.menu-topic {
  position: relative;
  width: 90%;
  box-sizing: border-box;
  margin: 10px auto;
  padding: 7px 10px 7px 0;
  transition: 200ms;
  border-radius: var(--component-radius-secondary);
  box-shadow: var(--component-shadow);

  @media only screen and (max-width: 1440px) {
    padding: 7px 5px;
  }

  &:hover,
  &.active {
    background: var(--blue-t3);
    cursor: pointer;
    color: var(--white);
  }

  &-icon {
    width: 45px;
    text-align: center;
    opacity: 0.4;
    padding-top: 3px;

    @media only screen and (max-width: 1440px) {
      width: 12px;
    }

    .menu-topic:hover &,
    .menu-topic.active & {
      opacity: 1;
    }

    & img {
      width: 15px;

      @media only screen and (max-width: 1440px) {
        display: none;
      }
    }
  }

  &-label {
    width: calc(100% - 60px);
    font-size: 12px;
    font-weight: 300;
    padding-right: 15px;

    @media only screen and (max-width: 1440px) {
      width: calc(100% - 23px);
      font-size: 11px;
    }

    @media only screen and (max-width: 867px) {
      width: calc(100% - 22px);
    }
  }

  &-go {
    padding-top: 1px;
    width: 7px;
    opacity: 0.4;
    margin-left: -8px;
    transition: 400ms;

    .menu-topic:hover & {
      opacity: 1;
      margin-left: 0;
    }
  }
}
