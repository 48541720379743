.background-elem {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(7, 51, 111);
  background: linear-gradient(
    34deg,
    rgba(7, 51, 111, 1) 0%,
    rgba(11, 66, 142, 1) 19%,
    rgba(95, 0, 77, 1) 34%,
    rgba(175, 18, 145, 1) 43%,
    rgba(55, 110, 186, 1) 54%,
    rgba(7, 51, 111, 1) 100%
  );
  background-attachment: fixed;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  z-index: 0;
}

.pf-right {
  position: relative;
  width: var(--dashboard-main-width);
  margin-left: calc(var(--dashboard-menu-width) + 25px);
  background: var(--lighter-grey);
  padding: 0 50px 0;
  vertical-align: top;
  border-radius: var(--component-radius);
  box-shadow: var(--component-shadow);
  overflow: hidden;

  @media only screen and (max-width: 1967px) {
    margin-left: 275px;
    width: calc(100% - 275px);
  }

  @media only screen and (max-width: 1496px) {
    margin-left: 260px;
    width: calc(100% - 260px);
  }

  @media only screen and (max-width: 1440px) {
    margin-left: 185px;
    width: calc(100% - 185px);
  }

  @media only screen and (max-width: 867px) {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
}

.pfr-header {
  padding: 25px 0;
  width: 100%;
  background: var(--lighter-grey);
  box-sizing: border-box;
  z-index: 50;

  @media only screen and (max-width: 867px) {
    padding: 15px 0 15px 45px;
  }

  // &.fixed {
  //   padding: 15px 100px 15px calc(var(--dashboard-menu-width) + 120px);
  //   box-shadow: var(--component-shadow);
  //   background: transparent;
  //   backdrop-filter: blur(10px);

  //   @media only screen and (max-width: 1967px) {
  //     padding: 15px 100px 15px calc(250px + 120px);
  //   }
  // }
}

.dm-left {
  font-size: 14px;
  padding-right: 25px;
  width: 47px;

  &.mobile-menu {
    display: none;
    cursor: pointer;

    @media only screen and (max-width: 867px) {
      display: inline-block;
      position: absolute;
      top: 25px;
      left: 15px;
      z-index: 61;
    }
  }

  & span {
    display: block;

    & div {
      height: 2px;
      background: var(--dark-grey);
      margin: 2px auto;
      transition: 200ms;
    }

    &.open-menu div {
      &:first-child {
        transform: rotate(45deg);
        margin-bottom: -2px;
      }

      &:nth-child(2) {
        display: none;
      }

      &:last-child {
        transform: rotate(-45deg);
        margin-top: -2px;
      }
    }
  }
}

.sentiment-charts {
  text-align: center;
}

.dashboard {
  &-title {
    font-size: 36px;
    font-weight: 500;
    color: var(--dark-grey);
    width: calc(100% - 50px);

    @media only screen and (max-width: 1120px) {
      font-size: 22px;
    }

    @media only screen and (max-width: 867px) {
      width: calc(100% - 50px);
    }
  }

  &-components {
    text-align: left;
  }

  &-line {
    display: flex;
    margin-bottom: 25px;

    @media only screen and (max-width: 1367px) {
      margin-bottom: 15px;

      &:nth-of-type(4) {
        flex-direction: column;
      }
    }

    @media only screen and (max-width: 1120px) {
      &:nth-of-type(2),
      &:nth-of-type(7) {
        flex-direction: column;
      }
    }

    @media only screen and (max-width: 1000px) {
      &:nth-of-type(6) {
        flex-direction: column;
      }
    }
  }
}

.chart {
  &-component {
    position: relative;
    border-radius: var(--component-radius);
    box-shadow: var(--component-shadow);
    padding: 15px 35px;
    vertical-align: top;
    min-height: 250px;
    transition: 200ms;
    background: var(--white);

    @media only screen and (max-width: 1967px) {
      padding: 15px 25px;
    }

    @media only screen and (max-width: 867px) {
      padding: 15px;
    }

    &:hover {
      box-shadow: var(--component-shadow-hover);
    }
  }

  &-title {
    text-align: center;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 400;
    color: var(--dark-grey);

    @media only screen and (max-width: 550px) {
      font-size: 16px;
    }

    &.align-left {
      text-align: left;
    }

    & img {
      vertical-align: middle;
      opacity: 0.5;
      width: 20px;
      margin-right: 15px;

      @media only screen and (max-width: 1796px) {
        width: 17px;
        margin-right: 7px;
      }

      @media only screen and (max-width: 867px) {
        width: 15px;
        margin-right: 3px;
      }
    }
  }
}

.platform {
  &-frame {
    padding-bottom: 40px;

    & .ps__rail-y {
      opacity: 1;
    }

    & .ps__rail-y:hover > .ps__thumb-y,
    & .ps__rail-y:focus > .ps__thumb-y,
    & .ps__rail-y.ps--clicking .ps__thumb-y {
      width: 8px;
      background-color: #79747e;
    }

    & .ps .ps__rail-y:hover,
    & .ps .ps__rail-y:focus,
    & .ps .ps__rail-y.ps--clicking {
      background: transparent;
    }
  }

  &-footer {
    position: relative;
    width: 100%;
    text-align: right;
    padding: 15px 0;
  }
}

.version-num {
  position: relative;
  display: inline-block;
  margin: 0;
  color: var(--white);
  font-size: 7px;
  text-align: center;
  font-weight: 200;
  background: var(--green-t5);
  border-radius: 5px;
  width: 50px;
  padding: 3px 0;
}
