.platform-frame .pf-left {
  position: fixed;
  background: var(--white);
  height: calc(100vh - 130px);
  width: var(--dashboard-menu-width);
  border-radius: var(--component-radius);
  z-index: 60;
  vertical-align: top;
  border-radius: var(--component-radius);
  box-shadow: var(--component-shadow);
  overflow: hidden;

  @media only screen and (max-width: 1967px) {
    width: 250px;
  }

  @media only screen and (max-width: 1440px) {
    width: 175px;
    height: calc(100vh - 120px);
  }

  @media only screen and (max-width: 867px) {
    display: none;

    &.open {
      display: block;
      width: 65%;
      height: 80%;
    }
  }

  & .logo {
    position: relative;
    width: 135px;
    margin: 40px auto 25px;

    @media only screen and (max-width: 867px) {
      height: 20%;
      width: auto;
    }

    & img {
      width: 100%;

      @media only screen and (max-width: 867px) {
        height: 100%;
        width: auto;
        display: block;
        margin: auto;
      }
    }
  }
}

.menu {
  position: relative;
  width: 100%;
  height: calc(100% - 251px);
  overflow-y: auto;

  @media only screen and (max-width: 867px) {
    height: calc(80% - 111px);
  }

  &-content {
    padding-right: 10px;
  }

  &-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 0;
    text-align: center;
    font-size: 9px;
    color: var(--dark-grey);
    border-top: 1px var(--light-grey) solid;
    background: var(--lighter-grey);
  }
}

.disclaimer {
  display: block;
  padding-bottom: 5px;
}
