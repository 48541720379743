.data-link {
  text-align: left;
  padding: 15px 10px;
  border-bottom: 1px var(--light-grey) solid;
  transition: 400ms;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: var(--lighter-grey);
    color: var(--blue-t4);

    & img {
      opacity: 0.75;
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  & img {
    transition: 400ms;
  }

  &-left {
    margin: 0;
    width: calc(100% - 70px);

    &:hover {
      & span {
        display: none;

        &.list-number {
          display: inline-block;
        }
      }

      & input {
        cursor: pointer;
        display: inline-block;
        color: var(--blue-t4);
      }
    }

    & img {
      opacity: 0.5;
      width: 16px;
      margin-right: 15px;
      vertical-align: middle;

      @media only screen and (max-width: 1667px) {
        margin-right: 10px;
      }

      &.sm {
        width: 8px;
      }
    }

    & span {
      font-size: 12px;

      @media only screen and (max-width: 1440px) {
        font-size: 11px;
      }

      &.list-number {
        display: inline-block;
        box-sizing: border-box;
        line-height: 18px;
        text-align: center;
        font-weight: 700;
        margin-right: 15px;
        font-size: 10px;
        color: var(--blue-t4);
        box-sizing: border-box;
        border-radius: 50%;
        width: 16px;
        height: 18px;
        background: var(--light-grey);
      }
    }

    & input {
      display: none;
      font-size: 12px;
      width: calc(100% - 31px);
      background: transparent;
      border: none;
      cursor: pointer;

      @media only screen and (max-width: 1440px) {
        font-size: 11px;
      }
    }
  }

  & &-icon {
    font-size: 10px;
    color: var(--dark-grey);
    text-align: right;
    margin: 0;
    display: flex;
    align-items: center;

    & span {
      margin-right: 10px;
    }

    & img {
      cursor: pointer;
      width: 15px;
      opacity: 0.5;
      vertical-align: middle;
    }
  }
}
